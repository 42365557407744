import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "./BaseUrl";
import Loader from "./Loader";
import jwt_decode from "jwt-decode";
import { useNavigate, Link } from 'react-router-dom';
 
const MFA = () => {
    const [mfa, setMfa] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
     const [token, setToken] = useState('');
      const [user_id, setUserId] = useState('');
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
    }, []);

    //  useEffect(() => {
    //     if (token) {
    //         sendMFACode();
    //         //getUserAccess();
    //     }
    // }, [token]);

    const refreshToken = async () => {
        try {
            setPopupOpen(true);
            const response = await axios.get(`${baseUrl}/mfa-token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            // setrole(decoded.role);
            // setName(decoded.name);
            setUserId(decoded.userId);
            // setExpire(decoded.exp);
            setPopupOpen(false);
        } catch (error) {
            
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const VerifyMFA = async (e) => {
        e.preventDefault();
       
        try {
            setPopupOpen(true);
            //console.log(token,'token');
         

            const mfaResponse =  await axios.post(`${baseUrl}/mfa`, {
                user_id: user_id,
                mfa:mfa 
            });
            console.log("mfaResponse",mfaResponse);
            if(mfaResponse.status === 200){
                setPopupOpen(false);
                setIsSuccess(true); // Set success state to true
                setMsg('Login successful');
                navigate("/dashboard");
            }
        } catch (error) {
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    }

    return (
        
        <section className="ragister-login loginpage">
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="row d-flex align-items-center">
                <div className="col-md-5 left-box">
                    <div className="inner-slider">

                    </div>
                </div>
                    <div className="col-md-7 right-box">
                        <div className="form-body">
                            <div className="left-logo">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                            </div>
                            <label className="label"><b>Please check your email for your one-time verification code.  Enter the code you received below to log in.</b></label>
                            <form onSubmit={VerifyMFA} className="form-box login-logo">
                                <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="field mt-5">
                                    <div className="controls">
                                        <input type="text" className="input" placeholder="MFA code" value={mfa} onChange={(e) => setMfa(e.target.value)} />
                                    </div>
                                </div>
                                {/* <div class="col-12">
                                    <div class="forgot-pass-link"><Link to="/forgot-password">Forgot Password?</Link></div>
                                </div> */}
                                <div className="field mt-5 logindiv">
                                    {/* <button className="button is-success is-fullwidth">Login</button> */}
                                    <input type="submit" value="Verify"></input>
                                </div>
                            </form>
                        </div>
                    </div>
                
            </div>
        </section>
    )
}
 
export default MFA
 