import React, { useState, useEffect } from 'react'
import axios from 'axios';
import baseUrl from "./BaseUrl";
import Loader from "./Loader";
import { useNavigate, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
 
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
    }, []);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/mfa-token`);
            if(response.msg == 'unauthentication'){
                navigate("/");  
            }else if(response.data.accessToken && response.data.is_mfa_verify==1){
                // setToken(response.data.accessToken);
                // const decoded = jwt_decode(response.data.accessToken);
                navigate("/dashboard");  
            }
        } catch (error) {
            if (error.response) {
                // setError("Unauthorized");
                //navigate("/");  
                //window.location.reload();
            }
        }
    }

    const Auth = async (e) => {
        e.preventDefault();
        try {
            setPopupOpen(true);

            // const api_secret = generatePassword();
            // const api_username = uuidv4();

            const loginResponse =  await axios.post(`${baseUrl}/login`, {
                email: email,
                password: password
            });
            console.log("loginResponse",loginResponse);
            console.log("MFA Status",loginResponse.data.mfa_status);
            // if(loginResponse.status === 200){

            //     const api_secret = generatePassword();
            //     const api_username = uuidv4();

            //     const cognitoUser =  await axios.post(`${baseUrl}/create-cognito-user-detail`, {
            //         uid:loginResponse.data.id,
            //         api_secret: api_secret,
            //         api_username: api_username,
            //         email: loginResponse.data.email
            //     });
            //     console.log("cognitoUser");
            //     console.log(cognitoUser);
            // }
            setPopupOpen(false);
            setIsSuccess(true); // Set success state to true
            setMsg('Login successful');
            if(loginResponse.data.mfa_status == 1){
                navigate("/mfa");
            }else{
                navigate("/dashboard");
            } 
        } catch (error) {
            setPopupOpen(false);
            if (error.response) {
                setIsSuccess(false);
                setMsg(error.response.data.msg);
            }
        }
    }

    // const generatePassword = () => {
    //     const length = 25;
    //     const numbers = '0123456789';
    //     const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
    //     const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //     const specialCharacters = '^$*.[]{}()?-@#%&/,><|_~+=';
    
    //     const allCharacters = numbers + lowerCase + upperCase + specialCharacters;
    
    //     let password = '';
    //     let hasNumber = false;
    //     let hasLowerCase = false;
    //     let hasUpperCase = false;
    //     let hasSpecialCharacter = false;
    
    //     // Ensure the password contains at least one of each required character type
    //     password += numbers[Math.floor(Math.random() * numbers.length)];
    //     hasNumber = true;
    
    //     password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
    //     hasLowerCase = true;
    
    //     password += upperCase[Math.floor(Math.random() * upperCase.length)];
    //     hasUpperCase = true;
    
    //     password += specialCharacters[Math.floor(Math.random() * specialCharacters.length)];
    //     hasSpecialCharacter = true;
    
    //     // Fill the rest of the password length with random characters
    //     for (let i = password.length; i < length; i++) {
    //         const character = allCharacters[Math.floor(Math.random() * allCharacters.length)];
    //         password += character;
    
    //         if (numbers.includes(character)) {
    //             hasNumber = true;
    //         } else if (lowerCase.includes(character)) {
    //             hasLowerCase = true;
    //         } else if (upperCase.includes(character)) {
    //             hasUpperCase = true;
    //         } else if (specialCharacters.includes(character)) {
    //             hasSpecialCharacter = true;
    //         }
    //     }
    
    //     // Shuffle the password to ensure randomness
    //     password = password.split('').sort(() => Math.random() - 0.5).join('');
    
    //     // Validate the generated password and regenerate if it doesn't meet all criteria
    //     if (!hasNumber || !hasLowerCase || !hasUpperCase || !hasSpecialCharacter) {
    //         return generatePassword();
    //     }
    
    //     return password;
    // };
 
    return (
        
        <section className="ragister-login loginpage">
            {popupOpen && (
                     <Loader /> 
                ) }
            <div className="row d-flex align-items-center">
                <div className="col-md-5 left-box">
                    <div className="inner-slider">

                    </div>
                </div>
                    <div className="col-md-7 right-box">
                        <div className="form-body">
                            <div className="left-logo">
                                <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                            </div>
                            <form onSubmit={Auth} className="form-box login-logo">
                                <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="field mt-5">
                                    {/* <label className="label">Email or Username</label> */}
                                    <div className="controls">
                                        <input type="email" className="input" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>
                                <div className="field mt-5">
                                    {/* <label className="label">Password</label> */}
                                    <div className="controls">
                                        <input type="password" className="input" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="forgot-pass-link"><Link to="/forgot-password">Forgot Password?</Link></div>
                                </div>
                                <div className="field mt-5 logindiv">
                                    {/* <button className="button is-success is-fullwidth">Login</button> */}
                                    <input type="submit" value="Sign In"></input>
                                </div>
                            </form>
                        </div>
                    </div>
                
            </div>
        </section>
    )
}
 
export default Login
 