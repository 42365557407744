/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const AddUser = () => {
    const [name, setName] = useState('');
    const [company, setCompany] = useState([]);
    const [role, setrole] = useState('');
    const [user_id, setUserId] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [msg, setMsg] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [formData, setFormData] = useState({
        user_role: '0',
        cname: '',
        fname: '',
        lname: '',
        email: '',
        officephone: '',
        mobilephone: '',
        pbx_switch: false,
        pbx_url:null,
        pbx_username:null,
        pbx_password:null
    });
    const [accessRight, setAccessRight] = useState({
        createuser: false,
        edituser: false,
        deleteuser: false,
        viewuser: false,
        ordernewnumber: false,
        viewtransactions: false,
        modifynumber: false,
        cancelnumber: false,
        viewinventory: false
    });
    const [setting, setSetting] = useState({
        disconnectSwitch: false,
        disconnectday: 0,
    });
    const [trunkData, setTrunksData] = useState([]);
    const [whiteLabelData, setWhiteLabelData] = useState([]);
    const [access, setAccess] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);

    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
      }, []);
    
      useEffect(() => {
        if (token) {
          getUserCompany();  
          getUserAccess();
        }
      }, [token]);
      useEffect(() => {
        getUserDetail();
      },[company]);
    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }        
    }, [access, token]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            
            setrole(decoded.role);
            setName(decoded.name);
            setUserId(decoded.userId);
            setExpire(decoded.exp);
            
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    

    const getUserAccess = async () => {
        try {
            const response = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
            
        } catch (error) {
           console.log(error);
          
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if((role === 2 && !hasPermission('createuser')) || role === 5){
            navigate('/dashboard');
        }
    }


    const handleSwitchChange = (event) => {
        const { name, checked } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: checked,
        }));
    };

    const handlePBXSwitchChange = (event) => {
        const { name, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    }

    const handleDayChange = (event) => {
        const { name, value } = event.target;
        setSetting((prevSetting) => ({
            ...prevSetting,
            [name]: parseInt(value),
        }));
    };

    const handleCheckboxChange = (event, name, type) => {
        const isChecked = event.target.checked;
        if(type === 0){
            if (isChecked) {
                setTrunksData([...trunkData, name]);
            } else {
                setTrunksData(trunkData.filter(n => n !== name));
            }
        }else{
            if (isChecked) {
                setWhiteLabelData([...whiteLabelData, name]);
            } else {
                setWhiteLabelData(whiteLabelData.filter(n => n !== name));
            }
        }
        
    };

   
    const getUserCompany = async () => {
        
        const response = await axios.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setCompany(response.data);
    }
    const getUserDetail = async () => {
        // const response = await axios.get(`${baseUrl}/user-company/${user_id}`, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // });
    //    console.log(role);
    //    console.log(company);
       if(role === 3 || role === 4 || role === 5){
        setFormData({
                user_role: 5,
                cname: company[0].id,
                // cid: userData.cname,
                fname: '',
                lname: '',
                email: '',
                officephone: '',
                mobilephone: ''
            });
       }
        // console.log(response.data);
        // const userData = response.data.user[0];
        // console.log(userData);
        // setFormData({
        //     user_role: '0',
        //     cname: userData.cid,
        //     cid: userData.cname,
        //     fname: '',
        //     lname: '',
        //     email: '',
        //     officephone: '',
        //     mobilephone: ''
        // });
    }

    const [validationErrors, setValidationErrors] = useState({
        user_role: false,
        email: false,
        cname: false,
        fname: false,
        lname: false,
        officephone: false,
        mobilephone: false

    });
    const formRef = useRef(null);
    const validateEmail = (email) => {
        // Implement your email validation logic here
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newValidationErrors = {
            user_role: formData.user_role === '0',
            email: !validateEmail(formData.email),
            cname: formData.cname === '',
            fname: formData.fname === '',
            lname: formData.lname === '',
            officephone: !/^\d{1,10}$/.test(formData.officephone),
            mobilephone: !/^\d{1,10}$/.test(formData.mobilephone)


          };
          setValidationErrors(newValidationErrors);
      
          // Check if there are any validation errors
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
      
          if (hasErrors) {
            
            // Find the first invalid input element
            const firstInvalidInput = formRef.current.querySelector('.invalid');
            console.log(firstInvalidInput);
            if (firstInvalidInput) {
              // Scroll to the first invalid input element
              firstInvalidInput.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          } else {
            // If no validation errors, proceed with form submission
            // console.log('Form submitted with data:', formData);
            // console.log(token);
            let rightsArray;
            
            if(formData.user_role == 3 || formData.user_role == 4){
                
                 rightsArray = Object.entries(accessRight).map(([key, value]) => ({
                    name: key,
                    value: 1,
                  }));
            }else{

                 rightsArray = Object.entries(accessRight).map(([key, value]) => ({
                    name: key,
                    value: value ? 1 : 0,
                  }));
            }
            
             
              const combinedData = {
                formData: formData,
                rightsArray: rightsArray,
                setting :setting,
                trunks: trunkData,
                whitelabel:whiteLabelData
              };
              console.log(combinedData);
            try {
                setButtonDisabled(true);
                setPopupOpen(true);
                await axios.post(`${baseUrl}/users`,combinedData,{headers: {
                    Authorization: `Bearer ${token}`
                }});
                setIsSuccess(true);
                setPopupOpen(false);
                navigate("/user");
            } catch (error) {
                setButtonDisabled(false);
                setPopupOpen(false);
                if (error.response) {
                    setIsSuccess(false);
                    setMsg(error.response.data.msg);
                    window.scrollTo(0, 0);
                }
            }
          }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'user_role' && value <= 2) {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                cname: '1' // Set cname to '1' if user_role is less than or equal to 2
            }));
            
            setTrunksData([]);
            setWhiteLabelData([]);
            console.log(company);
            
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
        }
    
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [name]: false
        }));
    };
    

      const accessRightLabels = {
        createuser: 'Create Users',
        edituser: 'Edit Users',
        deleteuser: 'Delete Users',
        viewuser: 'View Users',
        ordernewnumber: 'Order New Number',
        viewtransactions: 'View Orders',
        modifynumber: 'Modify Number',
        cancelnumber: 'Cancel Number',
        viewinventory: 'View Inventory'        
      };
    
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-new-addstaff-page">
                    <div className="fullrow">
                        <div className="row">
                            <div className="col-6">
                                <div className="left-title">
                                    <h1>Add User</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="addnew-button">
                                    <Link to="/user"><span>&larr;</span> Back</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <p className={`has-text-centered ${isSuccess ? 'success' : 'error'}`}>{msg}</p>
                                <div className="edit-staff-data-form add-new-staff-form">
                                    <form className="form-box" onSubmit={handleSubmit} ref={formRef}>
                                        <div className="common-white-shadow-background">
                                            <div className="innerboxwidth">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>User Info</h3>
                                                    </div>
                                                    <div className="col-6">
                                                    {(role !== 3 && role !== 4 && role !== 5) &&  <label>User Types</label>}
                                                        {(role !== 3 && role !== 4 && role !== 5) &&(<select name="user_role" id="user_role"  onChange={handleInputChange} className={validationErrors.user_role ? 'invalid' : ''} required>
                                                            <option value=""  >User Types</option>                
                                                            {/* {role === 0 && <option value="1">Super Admin</option>} */}
                                                            {(role <= 1 ||(role === 2 && hasPermission('createuser'))) && <option value="2">BBD Support User</option>}
                                                            {role <= 2 && <option value="3">Reseller admin user</option>}
                                                            {role < 3 && <option value="4">Customer admin user</option>} 
                                                            {(role <= 4 || hasPermission('createuser')) && <option value="5">Customer user</option>}
                                                            {/* {role <= 4 && <option value="6">Reseller support</option>} */}
                                                        </select> )}
                                                        {validationErrors.user_role && <div className="error error-message">Please select a user role.</div>}
                                                       
                                                    </div>
                                                    <div className="col-md-12">
                                                    {(role !== 3 && role !== 4 && role !== 5) && <label>Company</label>}
                                                        {(role !== 3 && role !== 4 && role !== 5) && <select name="cname" value={formData.cname} onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''}  required>
                                                            <option value=""  >Select Company</option>
                                                        
                                                            {formData.user_role <= 2 ? (
                                                                company.map((comp) => (
                                                                    comp.id === 1 && (
                                                                        <option key={comp.id} value={comp.id}>
                                                                            {comp.cname}
                                                                        </option>
                                                                    )
                                                                ))
                                                            ) : (
                                                                company.map((comp) => (
                                                                    comp.id !== 1 && (
                                                                        <option key={comp.id} value={comp.id}>
                                                                            {comp.cname}
                                                                        </option>
                                                                    )
                                                                ))
                                                            )}
                                                            
                                                        </select> 
                                                        }
                                                        {/* <input type="text" name="cname" defaultValue={formData.cid}
                                                        placeholder="Company Name*" onChange={handleInputChange} className={validationErrors.cname ? 'invalid' : ''} /> } */}
                                                        {validationErrors.cname && <div className="error error-message">Please Enter Company Name.</div>}
                                                    </div>
                                                    <div className="col-md-6">
                                                    <label>First Name</label>
                                                        <input type="text" name="fname" defaultValue={formData.fname}  onChange={handleInputChange} className={validationErrors.fname ? 'invalid' : ''} />
                                                        {validationErrors.fname && <div className="error error-message">Please Enter First Name.</div>} 
                                                        {/* placeholder="First Name*" */}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label>Last Name</label>
                                                        <input type="text" name="lname" defaultValue={formData.lname}  onChange={handleInputChange} className={validationErrors.lname ? 'invalid' : ''} />
                                                        {validationErrors.lname && <div className="error error-message">Please Enter last name.</div>}
                                                        {/* placeholder="Last Name*"  */}
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <label>Email</label>
                                                        <input type="email" name="email" defaultValue={formData.email}   onChange={handleInputChange} className={validationErrors.email ? 'invalid' : ''}/>
                                                        {validationErrors.email && <div className="error error-message">Please enter a valid email address.</div>}
                                                        {/* placeholder="Email" */}
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <label>Office Phone Number</label>
                                                        <input type="number" name="officephone" defaultValue={formData.officephone}
                                                            pattern="[0-9]*"   onChange={handleInputChange} className={validationErrors.officephone ? 'invalid' : ''} />
                                                            {validationErrors.officephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                            {/* placeholder="Office Phone Number*" */}
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label>Mobile Phone Number</label>
                                                        <input type="number" name="mobilephone" defaultValue={formData.mobilephone}
                                                            pattern="[0-9]*"   onChange={handleInputChange} className={validationErrors.mobilephone ? 'invalid' : ''} />
                                                            {validationErrors.mobilephone && <div className="error error-message">Phone number should not be longer than 10-digits.</div>}
                                                            {/* placeholder="Mobile Phone Number*" */}
                                                    </div>
                                                  
                                                    {(formData.user_role == '2' || formData.user_role == '5') && <div className="col-sm-12">
                                                        <p>Access Rights</p>
                                                        <div className="checkboximage">
                                                        {Object.entries(accessRight)
                                                        .filter(([key]) => {
                                                            // formData.user_role !== '5' || !['createuser', 'edituser', 'deleteuser', 'viewuser'].includes(key)
                                                            if (formData.user_role != '5') {
                                                                // If user_role is 5, no filtering is applied
                                                                return !['modifynumber'].includes(key);
                                                              } else {
                                                                // Otherwise, filter out specific keys (e.g., 'createuser', 'edituser', etc.)
                                                                return !['createuser', 'edituser', 'deleteuser', 'viewuser', 'modifynumber', 'viewinventory'].includes(key);
                                                              }
                                                            
                                                        })
                                                        .map(([key, value]) => (
                                                            <label key={key} htmlFor={key}>
                                                            <input type="checkbox" name={key} id={key} checked={value} onChange={(e) =>
                                                                setAccessRight((prevRights) => ({
                                                                  ...prevRights,
                                                                  [key]: e.target.checked,
                                                                }))}
                                                            />
                                                            {accessRightLabels[key]}
                                                            </label>
                                                        ))}
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                        
                                     {/* {(formData.user_role == '3' || formData.user_role == '4' || formData.user_role == '5') && <div className="common-white-shadow-background setting-section">
                                        <div className="innerboxwidth">
                                            <div className="row">
                                                <h3>Settings</h3>
                                                <div className="settingbox">
                                                        <label>Cloud PBX Access</label>
                                                        <div className="switchbtn">
                                                            <input type="checkbox" id="pbxswitch" className="checkbox"  onChange={handlePBXSwitchChange}  name="pbx_switch"   /> <label
                                                                htmlFor="pbxswitch" className="toggle">
                                                                <p>
                                                                    
                                                                </p>
                                                            </label>
                                                        </div>
                                                        <div className="daybox">
                                                        
                                                        </div>
                                                    </div>
                                                    {formData.pbx_switch && <div className="row">
                                                        
                                                        <div className="col-sm-12">
                                                            <input type="text" name="pbx_url" defaultValue={formData.pbx_url}
                                                              placeholder="PBX cloud url" onChange={handleInputChange}  />
                                                            
                                                        </div>
                                                        
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>}  */}
                                    {/* {formData.user_role == '0'  && formData.user_role == '2' && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>Trunks</h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA1', value:45265}, 0)} /></td>
                                                            <td>45265</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_TOR1',  value:45281}, 0)} /></td>
                                                            <td>45281</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_TOR1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_MN1',  value:45282}, 0)} /></td>
                                                            <td>45282</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_MN1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC1',  value:45283}, 0)} /></td>
                                                            <td>45283</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC1</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_LA2', value:45284}, 0)} /></td>
                                                            <td>45284</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_LA2</td>
                                                            
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'ORG BROADBAND-DYNAMICS_SLC2', value:45554}, 0)} /></td>
                                                            <td>45554</td>
                                                            <td colSpan="2">ORG BROADBAND-DYNAMICS_SLC2</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    {/* {formData.user_role == '0'  && formData.user_role == '2'  && <div className="common-white-shadow-background setting-section">
                                        <div className="row">
                                            <div className="col-7">
                                                <h3>White Label SBC Trunks <img src={`${process.env.PUBLIC_URL}/images/bx-refresh.png`} style={{background: '#f5f5f5', borderRadius: '2px'}} /></h3>
                                            </div>
                                            <div className="col-5">
                                                <input className="search-leave satff-listing-page-search" type="text"
                                                    name="search" placeholder="Search" style={{width:'100%'}} />
                                            </div>
                                            <div className="tablebox">
                                                <div className="fullwidth-table track-table-body staff-listing-admin-table-body">
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>TID</th>
                                                            <th colSpan="2">Name</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'LA2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">LA2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'VOIP_DLR_MIN_LD', value:1048}, 1)}/></td>
                                                            <td>1048</td>
                                                            <td colSpan="2">VOIP_DLR_MIN_LD</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_7012_DID', value:7012}, 1)}/></td>
                                                            <td>7012</td>
                                                            <td colSpan="2">MN2_BBD_7012_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'MN2_BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MN2_BBD_9644_DID</td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'BBD_9644_DID', value:9644}, 1)}/></td>
                                                            <td>9644</td>
                                                            <td>BBD_9644_DID</td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td><input type="checkbox" name="" id="" onChange={(e) => handleCheckboxChange(e, {name:'MIN_BBD_9644_DID', value:9644}, 1)} /></td>
                                                            <td>9644</td>
                                                            <td colSpan="2">MIN_BBD_9644_DID</td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>} */}
                                    <div className="col-12">
                                        <input type="submit" value="Add User" className={buttonDisabled ? 'yellow-button disabled-link' : 'yellow-button'} />
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                
            </div>
        </div>
    )
}
 
export default AddUser